@tailwind components;

@layer components {
    .image-left {
        @apply grid justify-items-start items-center;
    }
    .image-center {
        @apply grid justify-items-center items-center;
    }
    .image-right {
        @apply grid justify-items-end items-center;
    }
}
