@tailwind base;

@layer base {
  @font-face {
    font-family: Inter;
    font-display: swap;
    src: url('../assets/fonts/inter/Inter-Regular.ttf');
    font-display: fallback;
}

@font-face {
    font-family: Inter-Bold;
    font-display: swap;
    src: url('../assets/fonts/inter/Inter-Bold.ttf');
    
}

@font-face {
    font-family: Inter-ExtraBold;
    font-display: swap;
    src: url('../assets/fonts/inter/Inter-ExtraBold.ttf');
}

@font-face {
    font-family: Inter-SemiBold;
    font-display: swap;
    src: url('../assets/fonts/inter/Inter-SemiBold.ttf');
}

@font-face {
    font-family: Inter-Thin;
    font-display: swap;
    src: url('../assets/fonts/inter/Inter-Thin.ttf');
}

  html {
    font-family: 'Inter','Helvetica',sans-serif, system-ui, Helvetica;
  }

  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  h4 {
    @apply text-base;
  }
  h5 {
    @apply text-sm;
  }

  .h-97 {
    height: 24.25rem;
  }
  a {
    @apply cursor-pointer text-primary-normal hover:text-primary-hover;
  }
  b{
    @apply font-bold;
  }
  strong{
    @apply font-bold;
  }
 
}
