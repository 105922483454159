@tailwind components;

@layer components {
  /* Primary */
  .mock-border {
    @apply border-r-2 border-b-2 border-opacity-50 border-dashed pb-5 border-yellow-500
    /* bg-violet-50 border-violet-300 */
  }

  .invalid-component {
    @apply border-r-4 border-b-4 border-opacity-50 border-dashed pb-5 border-red-600
    /* bg-red-50 border-2 border-dotted pb-5 */
    /* border-violet-400 */
  }
}