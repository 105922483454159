@tailwind utilities;

@layer utilities {
  .w-45 {
    width: 11.25rem;
  }
  .content-auto {
    content-visibility: auto;
  }
}
