@tailwind components;

@layer components {
  /* Primary */
  .btn-primary-sm {
    @apply block items-center rounded border border-transparent bg-primary-normal px-2.5 py-1.5 text-xs font-medium text-white shadow-sm 
    hover:text-white hover:bg-primary-hover 
    focus:outline-none focus:ring-2 focus:ring-primary-focus focus:ring-offset-2;
  }
  .btn-primary-md {
    @apply block items-center rounded-md border border-transparent bg-primary-normal px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm 
    hover:text-white hover:bg-primary-hover 
    focus:outline-none focus:ring-2 focus:ring-primary-focus focus:ring-offset-2;
  }
  .btn-primary-lg {
    @apply block items-center rounded-md border border-transparent bg-primary-normal px-4 py-2 text-sm font-medium text-white shadow-sm 
    hover:text-white hover:bg-primary-hover 
    focus:outline-none focus:ring-2 focus:ring-primary-focus focus:ring-offset-2;
  }
  .btn-primary-xl{
    @apply block items-center rounded-md border border-transparent bg-Primary px-4 py-2 text-base font-medium text-white shadow-sm 
    hover:text-white hover:bg-primary-hover 
    focus:outline-none focus:ring-2 focus:ring-primary-focus focus:ring-offset-2
  }
  .btn-primary-2xl{
    @apply block items-center rounded-md border border-transparent bg-Primary px-6 py-3 text-base font-medium text-white shadow-sm 
    hover:text-white hover:bg-primary-hover 
    focus:outline-none focus:ring-2 focus:ring-primary-focus focus:ring-offset-2
  }
/* Secondary */
  .btn-secondary-sm {
    @apply block items-center rounded border border-transparent bg-secondary-normal px-2.5 py-1.5 text-xs font-medium text-white shadow-sm 
    hover:text-white hover:bg-secondary-hover 
    focus:outline-none focus:ring-2 focus:ring-secondary-focus focus:ring-offset-2;
  }
  .btn-secondary-md {
    @apply block items-center rounded-md border border-transparent bg-secondary-normal px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm 
    hover:text-white hover:bg-secondary-hover 
    focus:outline-none focus:ring-2 focus:ring-secondary-focus focus:ring-offset-2;
  }
  .btn-secondary-lg {
    @apply block items-center rounded-md border border-transparent bg-secondary-normal px-4 py-2 text-sm font-medium text-white shadow-sm 
    hover:text-white hover:bg-secondary-hover 
    focus:outline-none focus:ring-2 focus:ring-secondary-focus focus:ring-offset-2;
  }
  .btn-secondary-xl{
    @apply block items-center rounded-md border border-transparent bg-secondary-normal px-4 py-2 text-base font-medium text-white shadow-sm 
    hover:text-white hover:bg-secondary-hover 
    focus:outline-none focus:ring-2 focus:ring-secondary-focus focus:ring-offset-2
  }
  .btn-secondary-2xl{
    @apply block items-center rounded-md border border-transparent bg-secondary-normal px-6 py-3 text-base font-medium text-white shadow-sm 
    hover:text-white hover:bg-secondary-hover 
    focus:outline-none focus:ring-2 focus:ring-secondary-focus focus:ring-offset-2
  }
  /* Tertiary */
  .btn-tertiary-sm {
    @apply block items-center rounded border border-transparent bg-tertiary-normal px-2.5 py-1.5 text-xs font-medium text-white shadow-sm 
    hover:text-white hover:bg-tertiary-hover 
    focus:outline-none focus:ring-2 focus:ring-tertiary-focus focus:ring-offset-2;
  }
  .btn-tertiary-md {
    @apply block items-center rounded-md border border-transparent bg-tertiary-normal px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm 
    hover:text-white hover:bg-tertiary-hover 
    focus:outline-none focus:ring-2 focus:ring-tertiary-focus focus:ring-offset-2;
  }
  .btn-tertiary-lg {
    @apply block items-center rounded-md border border-transparent bg-tertiary-normal px-4 py-2 text-sm font-medium text-white shadow-sm 
    hover:text-white hover:bg-tertiary-hover 
    focus:outline-none focus:ring-2 focus:ring-tertiary-focus focus:ring-offset-2;
  }
  .btn-tertiary-xl{
    @apply block items-center rounded-md border border-transparent bg-tertiary-normal px-4 py-2 text-base font-medium text-white shadow-sm 
    hover:text-white hover:bg-tertiary-hover 
    focus:outline-none focus:ring-2 focus:ring-tertiary-focus focus:ring-offset-2
  }
  .btn-tertiary-2xl{
    @apply block items-center rounded-md border border-transparent bg-tertiary-normal px-6 py-3 text-base font-medium text-white shadow-sm 
    hover:text-white hover:bg-tertiary-hover 
    focus:outline-none focus:ring-2 focus:ring-tertiary-focus focus:ring-offset-2
  }
}